<template>
  <div class="elv-report-transactions-header">
    <el-tabs v-model="tabActiveName" type="border-card" class="elv-report-transactions-tab" @tab-click="onChangeTab">
      <el-tab-pane name="entity-transactions-list" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.transfer')
            }}<span>{{
              entityStore.entityDetail?.transactionTotal ? formatNumber(entityStore.entityDetail?.transactionTotal) : 0
            }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-transactions-trade-list" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('common.trade')
            }}<span>{{
              entityStore.entityDetail?.tradeTotal ? formatNumber(entityStore.entityDetail?.tradeTotal) : 0
            }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-transactions-gain-or-loss" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('project.costBasis.gainOrLoss')
            }}<span>{{
              entityStore.entityDetail?.gainLossTotal ? formatNumber(entityStore.entityDetail?.gainLossTotal) : 0
            }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-transactions-automation" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('menus.automation') }}
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>

    <div class="elv-report-transactions-header-operating">
      <template v-if="props.showJournalButton">
        <div
          v-if="isShowPostingStatus"
          v-loading="postingLoading"
          :class="{ 'is-disabled': entityStore.entityDetail?.progress.postingStatus === 'DOING' }"
          class="elv-transactions-header-main-right__posting"
          @click="onRecalculateCostBasis"
        >
          <template v-if="entityStore.entityDetail?.progress.postingStatus !== 'DOING'">
            <SvgIcon name="task-add" width="14" height="14" fill="#838D95" />
            <span>{{ t('button.postToLedger') }}</span>
            <el-tooltip
              v-if="
                entityStore.entityDetail?.progress.postingStatus === 'FINISHED' &&
                dayjs(entityStore.entityDetail?.progress.journalDoneTime).isAfter(
                  entityStore.entityDetail?.progress.postingDoneTime
                )
              "
              :show-arrow="true"
              effect="dark"
              placement="top"
              width="150"
              popper-class="elv-journal-posting-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <SvgIcon name="Information-Filled" width="14" height="14" fill="#2F63EB" />
              <template #content>{{ t('message.postToLedgerInfo') }}</template>
            </el-tooltip>
          </template>
          <template v-else>
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="" />
            <span>{{ t('progressStatus.postingToLedger') }}</span>
          </template>
        </div>
        <div
          v-if="props.showIntegrations"
          link
          class="elv-transactions-header-main-right__integrations"
          @click="onOpenIntegrationDialog"
        >
          <SvgIcon name="reports-integrations" width="14" height="14" />
          {{ t('common.integrations') }}
        </div>
        <elv-button
          v-if="isShowAddJournal"
          type="primary"
          round
          width="93"
          height="30"
          class="elv-financials-header-main-right__addRule"
          @click="emit('onAddJournal')"
          ><SvgIcon name="source-add" width="14" height="14" class="icon" />{{ t('button.addNew') }}</elv-button
        >
      </template>

      <elv-button
        v-if="props.showRuleButton"
        type="primary"
        round
        width="100"
        height="30"
        class="elv-financials-header-main-right__addRule"
        @click="emit('onAddRule')"
        ><SvgIcon name="source-add" width="14" height="14" class="icon" />{{ t('button.addRule') }}</elv-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import TransactionsApi from '@/api/TransactionsApi'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  journalsTotal: {
    type: [Number, String],
    default: 0
  },
  showIntegrations: {
    type: Boolean,
    default: false
  },
  showRuleButton: {
    type: Boolean,
    default: false
  },
  showJournalButton: {
    type: Boolean,
    default: false
  },
  isShowAddJournal: {
    type: Boolean,
    default: true
  },
  isShowPostingStatus: {
    type: Boolean,
    default: true
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()

const postingLoading = ref(false)
const tabActiveName = ref('reports-sources-detail')
const emit = defineEmits(['onAddRule', 'onAddJournal', 'onOpenIntegrationDialog'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangeTab = (tab: any) => {
  if (
    tab.props.name === 'entity-ledger-journals' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.viewList
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    tab.props.name === 'entity-transactions-list' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewList
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    tab.props.name === 'entity-transactions-automation' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  router.push({
    name: tab.props.name
  })
}

const onRecalculateCostBasis = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.posting
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.postingStatus !== 'DOING') {
    try {
      postingLoading.value = true
      await TransactionsApi.journalPosting(entityId.value)
      entityStore.fetchEntityDetail(entityId.value)
      ElMessage.success(t('message.postToLedgerStart'))
    } catch (error: any) {
      console.log(error)
      ElMessage.error(error.message)
    } finally {
      postingLoading.value = false
    }
  }
}

const onOpenIntegrationDialog = () => {
  emit('onOpenIntegrationDialog')
}

onMounted(() => {
  tabActiveName.value = String(route.name)
})
</script>

<style lang="scss" scoped>
.elv-report-transactions-header {
  position: relative;
}

.elv-report-transactions-tab {
  margin-top: 8px;
}

.elv-report-transactions-header-operating {
  position: absolute;
  top: 0px;
  right: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .elv-transactions-header-main-right__integrations {
    float: right;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    border: 0px;
    height: 32px;
    margin-bottom: 5px;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1e2024;
    cursor: pointer;
    margin-right: 16px;

    svg {
      fill: #838d95;
      margin-right: 6px;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):active {
      background-color: #fff;
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }
  }

  .elv-financials-header-main-right__addRule {
    padding: 0 12px;
    margin-bottom: 7px;

    .icon {
      fill: #fff;
      margin-right: 4px;
    }
  }

  .elv-transactions-header-main-right__posting {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 16px;
    margin-bottom: 5px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      display: block;
      animation: loading-rotate 2s linear infinite;
    }

    svg {
      fill: #838d95;
    }

    &.is-disabled {
      cursor: not-allowed;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):active {
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }

    :deep(.el-loading-spinner) {
      top: 130%;
      text-align: left;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>

<style lang="scss">
.el-popper.elv-journal-posting-tips {
  display: flex;
  padding: 5px 10px 8px 10px;
  box-sizing: border-box;
  width: 196px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
</style>
